.homeheader {
  background-color: #ffffff;
  padding: 80px 40px;
}

.homeheader h1 {
  color: #db291c;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
.homeheader h3 {
  font-size: 24px;
}
.homeheader h4 {
  color: #db291c;
  font-size: 24px;
}
.headercentertext {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: left;
  max-width: 1000px;
  margin: 30px auto;
}
.homeheaderbottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}
.homeheaderdivider {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.homeheaderdivider p {
  font-size: 14px !important;
}
.homeheaderdivider hr {
  width: calc(200px);
  border: none;
  height: 1px;
  background-color: #db291c;
}
.homeheaderbottom button,
.servicescardmain button,
.contactusbutton,
.talktousbutton,
.learnmorebutton {
  background-color: #db291c;
  color: white;

  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}
.talktousbutton {
  display: flex;
  gap: 10px;
  align-items: center;
}
.homeheaderbottom button:hover,
.contactusbutton:hover,
.talktousbutton:hover {
  background-color: #ff2a1a;
  transform: scale(1.1);
}
.servicescardmain button:hover,
.learnmorebutton:hover {
  background-color: #ff2a1a;
}
.homeheaderbottom p {
  color: #db291c;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.home h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}
.homeservices {
  padding: 30px 40px;
}
.servicesrow {
  flex-wrap: wrap;
  display: flex;
  gap: 30px;
}
.servicescard {
  width: 100%;
  max-width: calc(50% - 20px);
  min-width: 285px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background-color: #faf9f6;
  padding: 20px;
  color: #000000;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.servicescard:hover {
  scale: 1.05;
}
.servicescardtop {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.servicescardtop h4 {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}
.servicescardtop img {
  width: 30px;
  height: 30px;
}
.servicescardcontent p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.servicescard hr {
  margin: 20px 0px;
  background-color: #dcdcdc;
  height: 1px;
  border: none;
}
.servicescardmain {
  text-align: left;
}
.servicescardmain h5 {
  font-size: 16px;
  font-weight: 700;

  margin-bottom: 10px;
}
.servicescardmain ul {
  list-style: circle;
  padding: 0px 20px;
  margin-bottom: 20px;
}
.servicescardmain ul li {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.servicescardmain button {
  width: 100%;
}
.servicesimage img {
  max-width: 100%;
  height: 100px;
  border-radius: 100%;
}
.homeaboutus {
  display: flex;
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #535353;
  margin: 20px auto 40px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(120, 120, 120, 0.3);
  align-items: stretch;
  transition: all 0.3s ease;
}
.homeaboutus:hover {
  transform: scale(1.01);
}
.homeaboutusleft,
.homeaboutusright {
  display: flex;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.homeaboutusleft {
  width: 70%;
}
.homeaboutusright {
  width: 30%;
  align-items: flex-end;
}
.homeaboutusleft h2 {
  margin-bottom: 0px;
  text-align: left !important;
}
.homeaboutusright img {
  height: 300px;
}
.homeaboutusleft p {
  color: #db291c;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.homeaboutusleft h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.homeaboutusleft ul {
  list-style: circle;
  padding: 0px 20px;
  margin-bottom: 20px;
}
.homeaboutusleft ul li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.whatsapp-button,
.calendly-button {
  position: fixed;

  width: 60px;
  height: 60px;
  border-radius: 100%;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  align-items: center;
  z-index: 100;
  justify-content: center;
}
.calendly-button {
  bottom: 100px;
  right: 20px;
  background-color: #db291c;
}
.whatsapp-button {
  bottom: 20px;
  right: 20px;
  background-color: #24d366;
}
.whatsapp-button a,
.calendly-button a {
  height: 100%;
  color: #ffffff;
  display: flex;
  font-size: 35px;
  align-items: center;
  justify-content: center;
}
.calendly-button a {
  font-size: 30px;
}
@media screen and (max-width: 1000px) {
  .homeheader {
    padding: 60px 20px;
  }
  .homeheader h1 {
    font-size: 24px;
  }
  .homeheaderbottom {
    flex-direction: column;
    gap: 20px;
  }
  .servicesrow {
    justify-content: center;
  }
  .servicescard {
    max-width: calc(92%);
  }
  .homeheaderdivider hr {
    width: calc(100px);
    max-width: calc(50vw - 80px);
  }
  .homeheader h3 {
    font-size: 20px;
  }
  .homeaboutus {
    flex-direction: column;
  }
  .homeaboutusleft,
  .homeaboutusright {
    width: 100%;
  }
  .homeaboutusright img {
    width: 300px;
    max-width: 100%;
    object-fit: contain;
  }
}
