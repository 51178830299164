.navbarmobile-dropdown {
  position: fixed;
  top: 0px;
  z-index: 10;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  color: #ffffff;
  z-index: 1000;
  overflow-y: auto;
  padding: 0px;
}
.navbarmobile-dropdown .footertop {
  padding: 20px 40px;
}

.navbarmobile-dropdown.active {
  display: block;
}
.close-btn-navbar {
  font-size: 30px;
  color: #000000;
  margin-left: 11px;
  margin-right: 12px;
}

.navbarmobile-dropdown .navbarmain {
  background-color: #efebec;
}
.navbar {
  display: flex;
  flex-direction: column;
  background-color: #efebec;
  color: #ffffff;
}

.navbarbottom {
  display: flex;
  padding: 20px 10px;
  background-color: #141414;
  border-bottom: 1px solid #626262;
  justify-content: center;
}

.navbarbottom ul {
  display: flex;
  font-weight: 600;
  list-style-type: none;
  gap: 70px;
  color: #ffffff;
  font-size: 16px;
}

.navbarbottom ul li {
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbarbottom ul li:hover {
  color: #db291c;
  transform: scale(1.1);
}

.navbarmain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px 0px 0px;
}

.navbarleft,
.navbarcenter,
.navbarright {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbarleft {
  height: 120px;
  overflow: hidden;
}
.navbarmain img {
  height: 180px;
  border-radius: 5px;
}

.navbarright ul {
  list-style-type: none;
  display: flex;
  gap: 35px;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  margin-right: 20px;
}

.navbarright ul li {
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}
.navbarright ul li ion-icon {
  transition: rotate 0.3s ease;
}
.navbarright ul li:hover {
  color: #db291c;
  transform: scale(1.1);
  ion-icon {
    rotate: 180deg;
  }
}
.navbarright ul li span {
  display: flex;
  gap: 10px;
  color: #000000;
  align-items: center;
}

.navbarright ul li .dropdown-content {
  display: none;

  position: absolute;
  top: calc(100%);

  left: -75px;
  background-color: #efebec;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  width: 250px;
}

.navbarright ul li .dropdown-content ul {
  list-style: none;
  color: #ffffff;
  gap: 0px;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.navbarright ul li .dropdown-content ul li {
  padding: 8px 12px;
  padding: 15px;
  font-size: 12px;
  cursor: pointer;
  color: #000000;
  transition: all 0.3s ease;
}

.navbarright ul li .dropdown-content ul li:hover {
  background-color: #ffffff;
  color: #db291c;
  transform: scale(1);
}

.navbarright ul li:hover .dropdown-content {
  display: block;
}

.navbarright ul li:hover .dropdown-content,
.navbarright ul li .dropdown-content:hover {
  display: block;
}

.navbarright button {
  background-color: #db291c;
  color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 13px 40px;
  border-radius: 20px;
}

.navbarmobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .navbarmain {
    padding: 0px 10px;
  }
  .navbarleft {
    height: 90px;
    overflow: hidden;
  }
  .navbarmain img {
    height: 120px;
    border-radius: 5px;
  }
  .navbarright ul {
    display: none;
  }

  .navbarmobile {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .navbarmobile img {
    height: 40px;
  }

  .navbarbottom ul {
    width: 100%;
    gap: 0px;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1000px) {
  .navbarmobile-dropdown {
    display: none;
  }
}
