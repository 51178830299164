.testimonialheader {
  display: flex;
  justify-content: space-between;
  padding: 20px 80px 20px 60px;
  align-items: center;

  margin: 0 auto;
  width: 100%;
}

.testiominalsmain {
  padding: 10px 20px;
  background-color: #ffffff;
  display: flex;

  justify-content: center;
  flex-direction: column;
}
.testiominalsmain h1 {
  font-size: 40px;

  text-align: center;
}
.testiominalsmain h2 {
  color: #0047ff;
  line-height: 1.2;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.wrapper {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bottomarrows {
  display: flex;
  margin: 50px 0;
  gap: 20px;

  align-items: center;
}
.bottomarrows p {
  font-size: 16px;
}
.arrow {
  width: 40px;
  height: 40px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #ff2c1d;
  border: 1px solid #626262;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}
.arrow img {
  height: 20px;
}
.arrow:hover {
  background-color: #dc291c;
  scale: 1.1;
}

.readmore {
  color: #dc291c;
  font-size: 13px;
  margin-left: 2px;
}
.card-wrapper {
  width: 100%;
  display: grid;
  grid-column-gap: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 32%;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 10px;

  cursor: grab;
  scroll-snap-type: x;
  overflow-y: visible;

  scroll-padding-inline: 24px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.card-wrapper::-webkit-scrollbar {
  display: none;
}
.card-wrapper.grab {
  cursor: grabbing;
  user-select: none;
  scroll-snap-type: none;
  scroll-behavior: auto;
}
.card-item {
  padding: 20px;
  scroll-snap-align: start;
  border-radius: 30px;
  overflow: visible;

  background-color: #ffffff;
}

.splitstars {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.splitstarscross {
  margin-bottom: 5px;
}
.testimonial {
  font-family: "outfit", sans-serif;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 20px;
  overflow: visible;
  position: relative;
}

.testimonialstars {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.testimonial h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 5px;
}

.testimonial p {
  width: 95%;
  color: #000000;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}
.testimonialpost {
  font-weight: 300;
  font-size: 14px;
  font-size: 14px;
}
.testimonial-card {
  height: 300px;
}

@media screen and (max-width: 1000px) {
  .testimonialheader {
    flex-direction: column;
    padding: 10px 20px;
    gap: 20px;
  }
  .bottomarrows {
    justify-content: space-between;
    margin: 0px;
    width: 100%;
  }
  .testimonialheader h1 {
    line-height: 28px;
    font-size: 26px;
  }
  .carousel__item {
    width: 50%;
  }
  .card-wrapper {
    grid-auto-columns: calc(100% - 10px);
  }
  .testimonial p {
    width: 95%;

    font-size: 16px;
  }
  .testimonial h4 {
    font-size: 18px;
  }
  .card-item {
    padding: 0px;
  }

  .readmore {
    color: #dc291c;
    font-size: 16px;
    margin-left: 2px;
  }
  .testimonial-card {
    height: 350px;
  }
}
