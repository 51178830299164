.contactmain {
  background-color: hsl(0, 0%, 95%);
  display: flex;
  justify-content: center;
}
.contactform {
  display: flex;
  gap: 40px;
  max-width: 1800px;
  justify-content: space-around;
  background-color: hsl(0, 0%, 95%);
  padding: 40px;
}
.contactformleft {
  width: 44%;
  max-width: 400px;
}
.contactformleft h2 {
  text-align: left;
  margin-bottom: 15px;
}
.contactformleft p {
  text-align: left;
}

.contactformright {
  width: calc(56% - 20px);
  max-width: 1000px;
}
.contacttextorange {
  color: #db291c;
  text-decoration: underline;
}

.contactformright form {
  display: flex;
  flex-wrap: wrap;
}
.contactformright form input,
.contactformright form textarea {
  width: calc(100%);
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid #757575;
  padding: 15px 10px;
}
.inputdivhalf {
  display: flex;
  width: 100%;
  gap: 20px;
}

.contactformright form button {
  background-color: #db291c;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
  margin: 10px auto;
}
.contactformleft p {
  color: #4d5b68;
  font-size: 17px;
}
.contactleft h3 {
  margin-top: 10px;
}
.contactsocials {
  display: flex;
  gap: 20px;
  margin: 20px 0 20px 0;
}
.contactsocials ul {
  display: flex;
  gap: 20px;
  list-style-type: none;
}
.contactsocials ul li {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactsocials ul li img {
  height: 20px;
}
.contactinfo {
  width: 100%;
  display: flex;
  gap: 40px;

  flex-wrap: wrap;
  margin-top: 20px;
}
.contactelement {
  width: 40%;
  display: flex;
  gap: 10px;
  text-align: left;
  align-items: center;
}
.leftcontactelement {
  background-color: #db291c;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
}
.contactelement img {
  height: 25px;
}
.contactelement h3 {
  color: #4d5b68;
  font-size: 16px;
}
.contactelement p {
  color: #4d5b68;
  text-wrap: nowrap;
  font-size: 12px;
}
.checkboxdiv {
  display: flex;
  align-items: start;
  gap: 10px;
  text-align: left;
}
.checkboxbox {
  width: fit-content !important;
  margin: 8px 0px !important;
}
.checkboxdiv input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}
@media screen and (max-width: 1000px) {
  .contactform {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .contactformleft {
    width: 100%;
    max-width: 100%;
  }
  .contactformleft h2 {
    text-align: center;
    font-size: 24px;
  }
  .contactformleft p {
    text-align: left;
  }
  .contactformright {
    width: 100%;
  }
  .contactformright form input,
  .contactformright form textarea {
    width: 100%;
  }
  .inputdivhalf {
    flex-direction: column;
    gap: 0px;
  }
  .contactformright form button {
    width: 100%;
  }
  .contactelement {
    width: 100%;
  }
}
