.immigrationheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #0f1130;
  padding: 20px 40px;
  text-align: left;
}
.immigrationbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.immigrationbody h3 {
  color: #dc291c;
  font-size: 32px;
}
.immigrationbodyelement p {
  font-size: 18px;
  margin-bottom: 20px;
}
.immigrationbodyelementcolumns {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
}
.immigrationcard {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border: 1px solid #dedede;
  background-color: #0f1130;
  color: #fff;
  width: 80%;
  border-radius: 10px;
  padding: 20px 30px;
  transition: all 0.3s ease;
}
.immigrationcard:hover {
  transform: scale(1.05);
}
.immigrationcard hr {
  border: 1px solid #dedede;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.immigrationcardheader {
  display: flex;

  justify-content: space-between;
}
.immigrationcardheader h3 {
  color: #dc291c;
  font-size: 18px;
}
.immigrationcardheader p {
  color: #80afd3;
  cursor: pointer;

  text-decoration: underline;
}
.immigrationcardcontent p {
  text-align: left;
  margin-bottom: 20px;
}
.immigrationbodyelementcolumnshalf {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 20px;
}
.immigrationbodyelementcolumnshalf .immigrationcard {
  width: calc(50% - 10px);
}

@media screen and (max-width: 1000px) {
  .immigrationheader {
    padding: 20px 20px;
    flex-direction: column;
  }
  .immigrationbody {
    padding: 40px 20px;
  }
  .immigrationcard {
    width: 100%;
  }
  .immigrationbodyelementcolumnshalf {
    width: 90%;
  }
  .immigrationbodyelementcolumnshalf .immigrationcard {
    width: 100%;
  }
}
