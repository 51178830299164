.footer {
  background-color: #141414;
  padding: 40px 40px 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerabout {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footeraboutheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.footeraboutheader img {
  height: 120px;
  border-radius: 5px;
}
.footer ul li {
  cursor: pointer;
  transition: all 0.3s ease;
}
.footer ul li:hover {
  color: #db291c;
}
.footer hr {
  width: 100%;
  border: 1px solid #7c8082;
  margin: 20px 0;
}
.footertop {
  padding-top: 20px;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
}
.footerchild {
  text-align: left;
}
.footerchild h4 {
  font-size: 20px;
  width: fit-content;
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footerchild span ion-icon {
  display: none;
}
.footerchild h4:hover {
  color: #db291c;
}
.footerchild ul {
  list-style: none;
}
.footerchild ul li {
  font-size: 14px;
  margin-bottom: 15px;
}

.footerinnerchild {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}
.footerinnerchild h5 {
  color: #7c8082;
  margin-bottom: 10px;
}
.footerbottom {
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}
.footerbottomleft {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footerbottomleft p,
.footerbottomrightkey p {
  font-size: 17px;
  font-weight: 500;

  cursor: pointer;
}
.footerbottomleft img {
  height: 80px;
  border-radius: 5px;
}
.footerbottomright {
  display: flex;
  align-items: center;
  gap: 30px;
}
.footerbottomright img {
  height: 30px;
  cursor: pointer;
}
.footerbottomleftkeytags,
.footerbottomleftkey,
.footerbottomrightkey {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .footertop {
    justify-content: left;
    flex-direction: column;
    column-gap: 80px;
    padding-bottom: 20px;
    row-gap: 20px;
  }
  .footerchild {
    text-align: left;
  }
  .footerchild h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .footerchild ul li {
    font-size: 12px;
  }
  .footerbottom {
    flex-direction: column;
  }
  .footerbottomleft {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .footerbottomright {
    gap: 20px;
  }
  .footerchild span ion-icon {
    display: block;
    transition: all 0.3s ease;
  }
  .footerchild ul {
    display: none;
  }
  .activefooter ul {
    display: block;
  }
  .activefooter span ion-icon {
    transform: rotate(180deg);
    color: #db291c;
  }

  .footerinnerchild {
    margin-bottom: 0px;
  }
  .footerchild span {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }
  .activefooter span {
    margin-bottom: 20px;
  }
  .activefooter span h4 {
    color: #db291c;
  }
  .footerbottomrightkey {
    margin-bottom: 20px;
  }
  .footerbottomleft p,
  .footerbottomrightkey p {
    font-size: 14px;
  }
}
