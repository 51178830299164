@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
}

button {
  cursor: pointer;
}

.App {
  text-align: center;
}

*::-webkit-scrollbar {
  display: none;
}

a {
  cursor: pointer;
}
