.aboutheader {
  padding: 40px;
  background-color: #f1f1f1;

  width: 100%;

  text-align: left;
}
.aboutusmainheader {
  background-color: #fce4bf;
  padding: 70px 30px;
  width: 100%;

  text-align: center;
}
.aboutusmainheader h1 {
  color: #db291c;
  font-size: 40px;
  margin-bottom: 10px;
}
.aboutusmainheader p {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}
.missionsection {
  background-color: #fce4bf;
  padding: 70px 30px;
  width: 100%;
}
.missionsection h1 {
  color: #db291c;
  font-size: 40px;
  margin-bottom: 10px;
}
.missionsection p {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.aboutheader ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin: 0 auto;
  padding-left: 30px;
  max-width: 1400px;
}
.aboutheader h2 {
  margin: 0 auto;
  max-width: 1400px;
  color: #db291c;
  text-align: left;

  margin-top: 20px;
  margin-bottom: 20px;
}
.aboutheader p {
  width: 100%;
  margin: 0 auto;
}
.colorred {
  color: #db291c;
}
.centertext {
  text-align: center;
}
.aboutheader h1 {
  font-size: 40px;
  color: #db291c;
  margin-bottom: 10px;
  text-align: center;
}
.aboutheader p {
  line-height: 2;
  max-width: 1400px;
}
.corevalues ul strong {
  color: #db291c;
}
.graybgsection {
  background-color: #f1f1f1;
}
.graybgsection h1 {
  font-size: 40px;
  color: #db291c;
  margin-bottom: 10px;
  text-align: center;
}
.aboutusvisionelement {
  background-color: #f1f1f1;
}
.aboutusvision {
  text-align: left;
  margin-top: 20px;
}
.aboutusvision ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aboutusvision h2 {
  color: #db291c;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.homeservices h1 {
  margin-bottom: 15px;
}
.corevalues {
  background-color: #fce4bf;
}
@media screen and (max-width: 1000px) {
  .aboutheader p {
    text-align: left;
  }
  .homeservices h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .aboutusmainheader h1 {
    font-size: 32px;
  }
}
