.workpageheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #add1eb;
  padding: 20px 40px;
  gap: 20px;
  color: #000000;
}
.workheaderleft {
  width: 60%;
}
.workheaderright {
  width: 40%;
  justify-content: center;
  display: flex;
}
.workheaderleft h1 {
  color: #dc291c;
  text-align: left;
  font-size: 48px;
  margin-bottom: 10px;
}
.workheaderleft p {
  font-size: 16px;

  text-align: left;
  margin-bottom: 20px;
}
.workheaderright img {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}
.workpagemainbody {
  padding: 40px 20px;
}
.workpagecontent {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 40px;
}
.workpagecontentelement table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  text-align: left;
}
.workpagecontentelement table thead {
  border: 1px solid #ddd;
  text-align: center;
}
.workpagecontentelement table tbody {
  border: 1px solid #ddd;
}
.workpagecontentelement table th {
  background-color: #ff655b;
  font-size: 18px;
  padding: 10px;
  font-weight: 500;
}
.workpagecontentelement td {
  width: 33%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.workpagecomponentselector {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.workpagecomponentselector ul {
  display: flex;
  width: 100%;
  gap: 20px;
  list-style-type: none;
  justify-content: space-around;
}

.workpagecomponentselector ul li {
  padding: 20px 20px;
  background-color: #d0d0d0;
  color: #000000;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.selectedelement {
  background-color: #dc291c !important;
  color: #ffffff !important;
}

.workpagecomponentselector ul li:hover {
  background-color: #dc6960;
  color: #ffffff;
}
.workpagecontentelement {
  width: 100%;
}
.workpagecontentelement h3 {
  color: #3a3a3a;
  font-size: 32px;
  margin-top: 0px;

  margin-bottom: 20px;
}
.roundedcorners {
  border-radius: 100%;
}
.workpagecontentelement p {
  color: #3a3a3a;
  font-size: 16px;

  text-align: left;
  margin-bottom: 20px;
}
.workpagecontentelement ol {
  text-align: left !important;
}
.workpagecontentelement ol li {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 500;

  text-align: left !important;
  margin-bottom: 20px;
}
.workpagecontentelement h4 {
  color: #727272;
}
.workpagecontentelement ul {
  text-align: left !important;
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.workpagecontentelement ul li {
  color: #000000 !important;
  font-size: 16px;

  text-align: left !important;
}

.workpagecontentelement ul li u {
  font-weight: 500;
}
@media screen and (max-width: 1000px) {
  .workpagecomponentselector ul {
    flex-direction: column;
  }
  .workpagecomponentselector ul li {
    width: 100%;
  }
  .workpagecontent {
    padding: 20px;
  }
  .workpageheader {
    flex-direction: column;
    padding: 20px;
  }
  .workheaderleft {
    width: 100%;
    padding: 20px;
  }
  .workheaderleft h1 {
    font-size: 32px;
    text-align: center;
  }
  .workheaderleft p {
    text-align: center;
  }
  .workheaderright {
    width: 100%;
  }
  .workheaderright img {
    width: 100%;
    max-width: 400px;
  }
  .workpagecontentelement table th {
    font-size: 14px;
  }
  .workpagecontentelement table td {
    padding: 8px;
    font-size: 14px;
  }
  .workheaderright img {
    min-height: 250px;
  }
}
