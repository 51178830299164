.acceptcookies {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100000;
  width: calc(100% - 20px);
  max-width: 500px;
  background-color: #fff;
  padding-inline: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkboxdivaccept {
  margin-top: 40px;
  display: flex;
  text-align: left;
  align-items: start;
}
.checkboxdivaccept input[type="checkbox"] {
  margin: 10px 10px 10px 0;
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}
.acceptcookies img {
  height: 20px;
}

.acceptbutton {
  border-radius: 12px;
  background-color: #db291c;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 20px;
}
