.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: #ffffff;
  padding: 0px;
  border-radius: 5px;
  max-height: 90%;
  overflow-y: auto;
  max-width: 90%;
}

.closeicon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.closeiconcontainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.closeiconcontainer img {
  height: 20px;
  width: 20px;
}
.popup-content .contactusmainbody {
  box-shadow: none;
  margin-top: 0px;
}

.testimonial-popup {
  width: 600px;
  max-width: 80%;
  max-height: calc(100vh - 100px);
}
.testimonial-popup p {
  max-height: calc(100vh - 314px);
  overflow-y: auto;
}
.popup-content .contactusbody {
  background-color: #ffffff;
  padding: 10px 0px;
}
@media screen and (max-width: 1000px) {
  .testimonial-popup {
    padding: 15px;
  }
}
