.talktousmain {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.talktousmain h3 {
  font-size: 32px;
  margin-bottom: 20px;
}
.talktousmain p {
  max-width: 1000px;
  margin-bottom: 20px;
}
.talktousmain button {
  width: 200px;
  font-size: 20px;
}
