.dataprivacy,
.dataprivacybody {
  text-align: left;
  max-width: 1400px;
  padding: 10px 30px;
  margin: 0px auto;
}
.dataprivacy h1 {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.dataprivacy p {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;

  margin-top: 30px;
  color: #db291c;
}
.dataprivacybody ul {
  list-style: disc;
  margin-top: 0px;
  margin-left: 50px;
  margin-bottom: 10px;
}
.dataprivacybody h2 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #db291c;
}
.dataprivacybody h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dataprivacybody h4 {
  margin-bottom: 5px;
}
.dataprivacybody p {
  margin-bottom: 10px;
}
.dataprivacybody {
  margin-bottom: 40px !important;
}
